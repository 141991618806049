<div class="comisiones">
  <div class="container-lg">
    <div class="my-container">
      <nav aria-label="breadcrumb d-responsive">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">{{ "Inicio" | uppercase}}</li>
          <li class="breadcrumb-item quote-transform">{{ "Organización" | uppercase}}</li>
          <li class="breadcrumb-item quote-transform active" aria-current="page">
            <a class="nav-item" role="button">{{ "Comisiones" | uppercase}}</a>
          </li>
        </ol>
      </nav>
      <div class="section-title-container">
        <h3 class="page-title">
          Comisiones Permanentes 2024-2025
        </h3>
      </div>
      <div class="section-title-container pt-5 pb-1">
        <h3 class="section-title">
          {{ "Comisión de Seguridad Alimentaria, Asuntos Agroindustriales, Medio Ambiente, Recursos Naturales y Gestión de Riesgos" | uppercase }}
        </h3>
      </div>
      <!--  <div class="section-body-container">
        <div class="light-container p-5">
          <div class="row py-4 d-responsive">
            <div class="col-lg-2">
              <p class="group-title">
                No.
              </p>
            </div>
            <div class="col-lg-4">
              <p class="group-title">
                {{ "parlamentary_groups.group.text" |translate }}
              </p>
            </div>
            <div class="col-lg-3">
              <p class="group-title">
                {{ "parlamentary_groups.group.text_1" |translate }}
              </p>
            </div>
            <div class="col-lg-3">
              <p class="group-title">
                {{ "parlamentary_groups.group.text_2" |translate }}
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                1
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Nadia Lorena De León Torres
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Presidenta
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Guatemala
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                2
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Dalvier Amath Tuñón Reyes
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Vice-Presidente
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Panamá
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                3
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Perla Marcia Vargas Solis
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Secretario
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Nicaragua
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                4
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Lucas Evangelistico Aguilera Pineda
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Integrante
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Honduras
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                5
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Ángelo Roberto Bottazzi Suárez
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Integrante
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Honduras
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                6
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Manuel Isidro Castillero Guerra
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Integrante
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Panamá
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                7
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                José Ricardo Cruz
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Integrante
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                El Salvador
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                8
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Elida María Galeano Cornejo
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Integrante
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Nicaragua
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                9
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Carlos Guillermo Magaña Tobar
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Integrante
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                El Salvador
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                10
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Manuel Antonio Meneses Ruíz
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Integrante
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Guatemala
              </p>
            </div>
          </div>

          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                11
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Carlos Luis Sánchez Solimán
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Integrante
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                República Dominicana
              </p>
            </div>
          </div>
        </div>
      </div> -->

      <div class="section-body-container">
        <div class="light-container p-5">
          <div class="row py-4 d-responsive">
            <div class="col-lg-2">
              <p class="group-title">
                No.
              </p>
            </div>
            <div class="col-lg-4">
              <p class="group-title">
                {{ "parlamentary_groups.group.text" |translate }}
              </p>
            </div>
            <div class="col-lg-3">
              <p class="group-title">
                {{ "parlamentary_groups.group.text_1" |translate }}
              </p>
            </div>
            <div class="col-lg-3">
              <p class="group-title">
                {{ "parlamentary_groups.group.text_2" |translate }}
              </p>
            </div>
          </div>

          <div *ngFor="let sl of deputiesCAAP; let i = index;">
            <div class="row pb-4">
              <div class="col-lg-2">
                <p class="info-text">
                  {{i + 1}}
                </p>
              </div>
              <div class="col-lg-4">
                <p class="info-text">
                  {{sl.name}} {{sl.lastname}}
                </p>
              </div>
              <div class="col-lg-3">
                <p class="info-text">
                  {{sl.position}}
                </p>
              </div>
              <div class="col-lg-3">
                <p class="info-text">
                  {{sl.country}}
                </p>
              </div>
            </div>
          </div>



        </div>
      </div>
      <div class="section-title-container pt-5 pb-1">
        <h3 class="section-title">
          {{ "Comisión De Asuntos Jurídicos E Institucionalidad Regional" | uppercase }}
        </h3>
      </div>
      <!--   <div class="section-body-container">
        <div class="light-container p-5">
          <div class="row py-4 d-responsive">
            <div class="col-lg-2">
              <p class="group-title">
                No.
              </p>
            </div>
            <div class="col-lg-4">
              <p class="group-title">
                {{ "parlamentary_groups.group.text" |translate }}
              </p>
            </div>
            <div class="col-lg-3">
              <p class="group-title">
                {{ "parlamentary_groups.group.text_1" |translate }}
              </p>
            </div>
            <div class="col-lg-3">
              <p class="group-title">
                {{ "parlamentary_groups.group.text_2" |translate }}
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                1
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Gloria Guadalupe Oquelí Solórzano
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Presidenta
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Honduras
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                2
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Erwin Eduardo Velásquez Herrera
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Vice-Presidente
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Guatemala
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                3
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Nelson De La Cruz Alvarado
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Secretario
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                El Salvador
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                4
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                José Luis Oswaldo Cordova Martínez
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Integrante
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                El Salvador
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                5
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Élsido Antonio Díaz Bueno
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Integrante
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                República Dominicana
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                6
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Juan Pablo García Farinoni
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Integrante
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Panamá
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                7
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Edgar Salvador Navas Navas
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Integrante
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Nicaragua
              </p>
            </div>
          </div>

        </div>
      </div> -->

      <div class="section-body-container">
        <div class="light-container p-5">
          <div class="row py-4 d-responsive">
            <div class="col-lg-2">
              <p class="group-title">
                No.
              </p>
            </div>
            <div class="col-lg-4">
              <p class="group-title">
                {{ "parlamentary_groups.group.text" |translate }}
              </p>
            </div>
            <div class="col-lg-3">
              <p class="group-title">
                {{ "parlamentary_groups.group.text_1" |translate }}
              </p>
            </div>
            <div class="col-lg-3">
              <p class="group-title">
                {{ "parlamentary_groups.group.text_2" |translate }}
              </p>
            </div>
          </div>

          <div *ngFor="let sl of deputiesCAJIR; let i = index;">
            <div class="row pb-4">
              <div class="col-lg-2">
                <p class="info-text">
                  {{i + 1}}
                </p>
              </div>
              <div class="col-lg-4">
                <p class="info-text">
                  {{sl.name}} {{sl.lastname}}
                </p>
              </div>
              <div class="col-lg-3">
                <p class="info-text">
                  {{sl.position}}
                </p>
              </div>
              <div class="col-lg-3">
                <p class="info-text">
                  {{sl.country}}
                </p>
              </div>
            </div>
          </div>



        </div>
      </div>
      <div class="section-title-container pt-5 pb-1">
        <h3 class="section-title">
          {{ "Comisión de Asuntos Municipales y Desarrollo de Ciudadanía" | uppercase }}
        </h3>
      </div>
      <!--  <div class="section-body-container">
        <div class="light-container p-5">
          <div class="row py-4 d-responsive">
            <div class="col-lg-2">
              <p class="group-title">
                No.
              </p>
            </div>
            <div class="col-lg-4">
              <p class="group-title">
                {{ "parlamentary_groups.group.text" |translate }}
              </p>
            </div>
            <div class="col-lg-3">
              <p class="group-title">
                {{ "parlamentary_groups.group.text_1" |translate }}
              </p>
            </div>
            <div class="col-lg-3">
              <p class="group-title">
                {{ "parlamentary_groups.group.text_2" |translate }}
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                1
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Fernando Bautista Andreu
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Presidente
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                El Salvador
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                2
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Giselle De Lourdes Burrillo Saiz
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Vice-Presidenta
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Panamá
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                3
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Eva Nicolle Monte Bac 
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Secretaria
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Guatemala
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                4
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Julio César Blandón Sánchez
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Integrante
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Nicaragua
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                5
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Oscar Orlando Burgos
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Integrante
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Honduras
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                6
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Bienvenido Casado
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Integrante
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                República Dominicana
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                7
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Eugenio Chicas Martínez
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Integrante
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                El Salvador
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                8
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Santiago De Jesús Rodríguez Peña
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Integrante
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                República Dominicana
              </p>
            </div>
          </div>

          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                9
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Joaquín Flores España
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Integrante
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Guatemala
              </p>
            </div>
          </div>


          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                10
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Luz Margarita Vásquez Escobar
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Integrante
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Panamá
              </p>
            </div>
          </div>
        </div>
      </div> -->

      <div class="section-body-container">
        <div class="light-container p-5">
          <div class="row py-4 d-responsive">
            <div class="col-lg-2">
              <p class="group-title">
                No.
              </p>
            </div>
            <div class="col-lg-4">
              <p class="group-title">
                {{ "parlamentary_groups.group.text" |translate }}
              </p>
            </div>
            <div class="col-lg-3">
              <p class="group-title">
                {{ "parlamentary_groups.group.text_1" |translate }}
              </p>
            </div>
            <div class="col-lg-3">
              <p class="group-title">
                {{ "parlamentary_groups.group.text_2" |translate }}
              </p>
            </div>
          </div>

          <div *ngFor="let sl of deputiesCDMPC; let i = index;">
            <div class="row pb-4">
              <div class="col-lg-2">
                <p class="info-text">
                  {{i + 1}}
                </p>
              </div>
              <div class="col-lg-4">
                <p class="info-text">
                  {{sl.name}} {{sl.lastname}}
                </p>
              </div>
              <div class="col-lg-3">
                <p class="info-text">
                  {{sl.position}}
                </p>
              </div>
              <div class="col-lg-3">
                <p class="info-text">
                  {{sl.country}}
                </p>
              </div>
            </div>
          </div>



        </div>
      </div>
      <div class="section-title-container pt-5 pb-1">
        <h3 class="section-title">
          {{ "Comisión De Educación, Cultura, Deporte, Ciencia Y Tecnología" | uppercase }}
        </h3>
      </div>
      <!-- <div class="section-body-container">
        <div class="light-container p-5">
          <div class="row py-4 d-responsive">
            <div class="col-lg-2">
              <p class="group-title">
                No.
              </p>
            </div>
            <div class="col-lg-4">
              <p class="group-title">
                {{ "parlamentary_groups.group.text" |translate }}
              </p>
            </div>
            <div class="col-lg-3">
              <p class="group-title">
                {{ "parlamentary_groups.group.text_1" |translate }}
              </p>
            </div>
            <div class="col-lg-3">
              <p class="group-title">
                {{ "parlamentary_groups.group.text_2" |translate }}
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                1
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Kimberly Saraí Guevara Miralda
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Presidenta
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Honduras
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                2
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Juan Gerardo Guerrero Garnica
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Vice-Presidente
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Guatemala
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                3
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Jackeline Del Carmen Muñoz Cedeño
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Secretaria 
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Panamá
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                4
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Matilde Mercedes Bonilla Rosales
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Integrante
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Honduras
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                5
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Luis Jorge Coronado Suárez
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Integrante
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                República Dominicana
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                6
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Kenia Xiomara Guante Valdez
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Integrante
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                República Dominicana
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                7
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                María Esperanza Valle Buitrago
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Integrante
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Nicaragua
              </p>
            </div>
          </div>
        </div>
      </div> -->

      <div class="section-body-container">
        <div class="light-container p-5">
          <div class="row py-4 d-responsive">
            <div class="col-lg-2">
              <p class="group-title">
                No.
              </p>
            </div>
            <div class="col-lg-4">
              <p class="group-title">
                {{ "parlamentary_groups.group.text" |translate }}
              </p>
            </div>
            <div class="col-lg-3">
              <p class="group-title">
                {{ "parlamentary_groups.group.text_1" |translate }}
              </p>
            </div>
            <div class="col-lg-3">
              <p class="group-title">
                {{ "parlamentary_groups.group.text_2" |translate }}
              </p>
            </div>
          </div>

          <div *ngFor="let sl of deputiesCECDCT; let i = index;">
            <div class="row pb-4">
              <div class="col-lg-2">
                <p class="info-text">
                  {{i + 1}}
                </p>
              </div>
              <div class="col-lg-4">
                <p class="info-text">
                  {{sl.name}} {{sl.lastname}}
                </p>
              </div>
              <div class="col-lg-3">
                <p class="info-text">
                  {{sl.position}}
                </p>
              </div>
              <div class="col-lg-3">
                <p class="info-text">
                  {{sl.country}}
                </p>
              </div>
            </div>
          </div>



        </div>
      </div>
      <div class="section-title-container pt-5 pb-1">
        <h3 class="section-title">
          {{ "Comisión De Integración, Comercio Y Desarrollo Económico" | uppercase }}
        </h3>
      </div>
      <!-- <div class="section-body-container">
        <div class="light-container p-5">
          <div class="row py-4 d-responsive">
            <div class="col-lg-2">
              <p class="group-title">
                No.
              </p>
            </div>
            <div class="col-lg-4">
              <p class="group-title">
                {{ "parlamentary_groups.group.text" |translate }}
              </p>
            </div>
            <div class="col-lg-3">
              <p class="group-title">
                {{ "parlamentary_groups.group.text_1" |translate }}
              </p>
            </div>
            <div class="col-lg-3">
              <p class="group-title">
                {{ "parlamentary_groups.group.text_2" |translate }}
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                1
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Jacques Emanuel Seidner Weiss
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Presidente
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Guatemala
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                2
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Sandra Iberia Noriega Sieiro
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Vice-Presidenta 
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Panamá
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                3
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Ana Victoria Hernández Pérez
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Secretaria
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Guatemala
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                4
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Irma Segunda Amaya Echverría
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Integrante
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                El Salvador
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                5
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Jóse Gabriel Liz Reyes
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Integrante
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                República Dominicana
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                6
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Jimmy Morales Cabrera
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Integrante
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Guatemala
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                7
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Guillermo Antonio Osorno Molina
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Integrante
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Nicaragua
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                8
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Ricardo Tomas Polanco Reinoso
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Integrante
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                República Dominicana
              </p>
            </div>
          </div>
        </div>
      </div>
 -->

      <div class="section-body-container">
        <div class="light-container p-5">
          <div class="row py-4 d-responsive">
            <div class="col-lg-2">
              <p class="group-title">
                No.
              </p>
            </div>
            <div class="col-lg-4">
              <p class="group-title">
                {{ "parlamentary_groups.group.text" |translate }}
              </p>
            </div>
            <div class="col-lg-3">
              <p class="group-title">
                {{ "parlamentary_groups.group.text_1" |translate }}
              </p>
            </div>
            <div class="col-lg-3">
              <p class="group-title">
                {{ "parlamentary_groups.group.text_2" |translate }}
              </p>
            </div>
          </div>

          <div *ngFor="let sl of deputiesCICDE; let i = index;">
            <div class="row pb-4">
              <div class="col-lg-2">
                <p class="info-text">
                  {{i + 1}}
                </p>
              </div>
              <div class="col-lg-4">
                <p class="info-text">
                  {{sl.name}} {{sl.lastname}}
                </p>
              </div>
              <div class="col-lg-3">
                <p class="info-text">
                  {{sl.position}}
                </p>
              </div>
              <div class="col-lg-3">
                <p class="info-text">
                  {{sl.country}}
                </p>
              </div>
            </div>
          </div>



        </div>
      </div>
      <div class="section-title-container pt-5 pb-1">
        <h3 class="section-title">
          {{ "Comisión De La Mujer, Niñez, Juventud Y Familia" | uppercase }}
        </h3>
      </div>
      <!-- <div class="section-body-container">
        <div class="light-container p-5">
          <div class="row py-4 d-responsive">
            <div class="col-lg-2">
              <p class="group-title">
                No.
              </p>
            </div>
            <div class="col-lg-4">
              <p class="group-title">
                {{ "parlamentary_groups.group.text" |translate }}
              </p>
            </div>
            <div class="col-lg-3">
              <p class="group-title">
                {{ "parlamentary_groups.group.text_1" |translate }}
              </p>
            </div>
            <div class="col-lg-3">
              <p class="group-title">
                {{ "parlamentary_groups.group.text_2" |translate }}
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                1
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Aida Ester Blanco Talavera
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Presidenta
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Nicaragua
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                2
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Rachel González Santos De Rodríguez
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Vice-presidenta
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Panamá
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                3
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Mónica Gabriela Broncy García
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Secretaria
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Guatemala
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                4
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Silvia García Polanco
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Integrante
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                República Dominicana
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                5
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Heidy Carolina Mira Saravia
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Integrante
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                El Salvador
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                6
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                María Dilma Quezada
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Integrante
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Honduras
              </p>
            </div>
          </div>

          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                7
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Doris María Tijerino Haslam
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Integrante
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Nicaragua
              </p>
            </div>
          </div>
        </div>
      </div> -->

      <div class="section-body-container">
        <div class="light-container p-5">
          <div class="row py-4 d-responsive">
            <div class="col-lg-2">
              <p class="group-title">
                No.
              </p>
            </div>
            <div class="col-lg-4">
              <p class="group-title">
                {{ "parlamentary_groups.group.text" |translate }}
              </p>
            </div>
            <div class="col-lg-3">
              <p class="group-title">
                {{ "parlamentary_groups.group.text_1" |translate }}
              </p>
            </div>
            <div class="col-lg-3">
              <p class="group-title">
                {{ "parlamentary_groups.group.text_2" |translate }}
              </p>
            </div>
          </div>

          <div *ngFor="let sl of deputiesCMNJF; let i = index;">
            <div class="row pb-4">
              <div class="col-lg-2">
                <p class="info-text">
                  {{i + 1}}
                </p>
              </div>
              <div class="col-lg-4">
                <p class="info-text">
                  {{sl.name}} {{sl.lastname}}
                </p>
              </div>
              <div class="col-lg-3">
                <p class="info-text">
                  {{sl.position}}
                </p>
              </div>
              <div class="col-lg-3">
                <p class="info-text">
                  {{sl.country}}
                </p>
              </div>
            </div>
          </div>



        </div>
      </div>
      <div class="section-title-container pt-5 pb-1">
        <h3 class="section-title">
          {{ "Comisión De Macroeconomía Y Finanzas" | uppercase }}
        </h3>
      </div>
      <!-- <div class="section-body-container">
        <div class="light-container p-5">
          <div class="row py-4 d-responsive">
            <div class="col-lg-2">
              <p class="group-title">
                No.
              </p>
            </div>
            <div class="col-lg-4">
              <p class="group-title">
                {{ "parlamentary_groups.group.text" |translate }}
              </p>
            </div>
            <div class="col-lg-3">
              <p class="group-title">
                {{ "parlamentary_groups.group.text_1" |translate }}
              </p>
            </div>
            <div class="col-lg-3">
              <p class="group-title">
                {{ "parlamentary_groups.group.text_2" |translate }}
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                1
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Donald Ricardo Calderón Lam
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Presidente
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                El Salvador
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                2
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Eduardo Rey Guerrero Solano
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Vice-presidente
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                República Dominicana
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                3
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Juan Pablo Riley Portillo
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Secretario
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Guatemala
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                4
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Ana Ingird Bernat Cofiño
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Integrante
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Guatemala
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                5
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Francisco Nazario Cano Torrez
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Integrante
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Nicaragua
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                6
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Wilfredo Francisco Cerrato Durón
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Integrante
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Hoduras
              </p>
            </div>
          </div>

          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                7
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Rubén De León Sánchez
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Integrante
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Panamá
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                8
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Bill O´Neil Santos Brito
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Integrante
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Honduras
              </p>
            </div>
          </div>
        </div>
      </div> -->

      <div class="section-body-container">
        <div class="light-container p-5">
          <div class="row py-4 d-responsive">
            <div class="col-lg-2">
              <p class="group-title">
                No.
              </p>
            </div>
            <div class="col-lg-4">
              <p class="group-title">
                {{ "parlamentary_groups.group.text" |translate }}
              </p>
            </div>
            <div class="col-lg-3">
              <p class="group-title">
                {{ "parlamentary_groups.group.text_1" |translate }}
              </p>
            </div>
            <div class="col-lg-3">
              <p class="group-title">
                {{ "parlamentary_groups.group.text_2" |translate }}
              </p>
            </div>
          </div>

          <div *ngFor="let sl of deputiesCMF; let i = index;">
            <div class="row pb-4">
              <div class="col-lg-2">
                <p class="info-text">
                  {{i + 1}}
                </p>
              </div>
              <div class="col-lg-4">
                <p class="info-text">
                  {{sl.name}} {{sl.lastname}}
                </p>
              </div>
              <div class="col-lg-3">
                <p class="info-text">
                  {{sl.position}}
                </p>
              </div>
              <div class="col-lg-3">
                <p class="info-text">
                  {{sl.country}}
                </p>
              </div>
            </div>
          </div>



        </div>
      </div>
      <div class="section-title-container pt-5 pb-1">
        <h3 class="section-title">
          {{ "Comisión De Pueblos Indígenas Y Afrodescendientes" | uppercase }}
        </h3>
      </div>
      <!-- <div class="section-body-container">
        <div class="light-container p-5">
          <div class="row py-4 d-responsive">
            <div class="col-lg-2">
              <p class="group-title">
                No.
              </p>
            </div>
            <div class="col-lg-4">
              <p class="group-title">
                {{ "parlamentary_groups.group.text" |translate }}
              </p>
            </div>
            <div class="col-lg-3">
              <p class="group-title">
                {{ "parlamentary_groups.group.text_1" |translate }}
              </p>
            </div>
            <div class="col-lg-3">
              <p class="group-title">
                {{ "parlamentary_groups.group.text_2" |translate }}
              </p>
            </div>
          </div>

          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                1
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Amilcar De Jesús Pop AC
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Presidente
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Guatemala
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                2
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Sidney Orlington Francis Martin
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Vice-presidente
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Nicaragua
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                3
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Ava Rossana Guevara Pinto
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Secretaria
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Honduras
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                4
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Pedro Alejandro Aguirre Hernández
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Integrante
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                República Dominicana
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                5
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Blanca Flor America Bonilla
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Integrante
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                El Salvador
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                6
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Anthony Ariel Pavón Almendarez
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Integrante
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Honduras
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                7
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Héctor Abdiel Valderrama Taborda
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Integrante
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Panamá
              </p>
            </div>
          </div>
        </div>
      </div> -->

      <div class="section-body-container">
        <div class="light-container p-5">
          <div class="row py-4 d-responsive">
            <div class="col-lg-2">
              <p class="group-title">
                No.
              </p>
            </div>
            <div class="col-lg-4">
              <p class="group-title">
                {{ "parlamentary_groups.group.text" |translate }}
              </p>
            </div>
            <div class="col-lg-3">
              <p class="group-title">
                {{ "parlamentary_groups.group.text_1" |translate }}
              </p>
            </div>
            <div class="col-lg-3">
              <p class="group-title">
                {{ "parlamentary_groups.group.text_2" |translate }}
              </p>
            </div>
          </div>

          <div *ngFor="let sl of deputiesCPIA; let i = index;">
            <div class="row pb-4">
              <div class="col-lg-2">
                <p class="info-text">
                  {{i + 1}}
                </p>
              </div>
              <div class="col-lg-4">
                <p class="info-text">
                  {{sl.name}} {{sl.lastname}}
                </p>
              </div>
              <div class="col-lg-3">
                <p class="info-text">
                  {{sl.position}}
                </p>
              </div>
              <div class="col-lg-3">
                <p class="info-text">
                  {{sl.country}}
                </p>
              </div>
            </div>
          </div>



        </div>
      </div>
      <div class="section-title-container pt-5 pb-1">
        <h3 class="section-title">
          {{ "Comisión de Relaciones Exteriores y Migración" | uppercase }}
        </h3>
      </div>
      <!-- <div class="section-body-container">
        <div class="light-container p-5">
          <div class="row py-4 d-responsive">
            <div class="col-lg-2">
              <p class="group-title">
                No.
              </p>
            </div>
            <div class="col-lg-4">
              <p class="group-title">
                {{ "parlamentary_groups.group.text" |translate }}
              </p>
            </div>
            <div class="col-lg-3">
              <p class="group-title">
                {{ "parlamentary_groups.group.text_1" |translate }}
              </p>
            </div>
            <div class="col-lg-3">
              <p class="group-title">
                {{ "parlamentary_groups.group.text_2" |translate }}
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                1
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Carlos Rodolfo Outten González
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Presidente
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Panamá
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                2
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Guillermo Daniel Ortega Reyes
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Vice-presidente
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Nicaragua
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                3
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Higinio Antonio Báez Ureña
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Secretario
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                República Dominicana
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                4
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Jóse Antonio Alfonseca Suncar
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Integrante
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                República Dominicana
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                5
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Dorindo Jayan Cortez Marciaga
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Integrante
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Panamá
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                6
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                María Mirtala López Mejía
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Integrante
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                El Salvador
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                7
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Eduardo Fernando Ponce Aguilar
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Integrante
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Guatemala
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                8
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Francisco Javier Ramos Sánchez
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Integrante
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Nicaragua
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                9
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Erick Haroldo Rodas Ruano
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Integrante
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Guatemala
              </p>
            </div>
          </div>
        </div>
      </div> -->

      <div class="section-body-container">
        <div class="light-container p-5">
          <div class="row py-4 d-responsive">
            <div class="col-lg-2">
              <p class="group-title">
                No.
              </p>
            </div>
            <div class="col-lg-4">
              <p class="group-title">
                {{ "parlamentary_groups.group.text" |translate }}
              </p>
            </div>
            <div class="col-lg-3">
              <p class="group-title">
                {{ "parlamentary_groups.group.text_1" |translate }}
              </p>
            </div>
            <div class="col-lg-3">
              <p class="group-title">
                {{ "parlamentary_groups.group.text_2" |translate }}
              </p>
            </div>
          </div>

          <div *ngFor="let sl of deputiesCRIAM; let i = index;">
            <div class="row pb-4">
              <div class="col-lg-2">
                <p class="info-text">
                  {{i + 1}}
                </p>
              </div>
              <div class="col-lg-4">
                <p class="info-text">
                  {{sl.name}} {{sl.lastname}}
                </p>
              </div>
              <div class="col-lg-3">
                <p class="info-text">
                  {{sl.position}}
                </p>
              </div>
              <div class="col-lg-3">
                <p class="info-text">
                  {{sl.country}}
                </p>
              </div>
            </div>
          </div>



        </div>
      </div>
      <div class="section-title-container pt-5 pb-1">
        <h3 class="section-title">
          {{ "Comisión de Salud y Seguridad Social" | uppercase
          }}
        </h3>
      </div>
      <!-- <div class="section-body-container">
        <div class="light-container p-5">
          <div class="row py-4 d-responsive">
            <div class="col-lg-2">
              <p class="group-title">
                No.
              </p>
            </div>
            <div class="col-lg-4">
              <p class="group-title">
                {{ "parlamentary_groups.group.text" |translate }}
              </p>
            </div>
            <div class="col-lg-3">
              <p class="group-title">
                {{ "parlamentary_groups.group.text_1" |translate }}
              </p>
            </div>
            <div class="col-lg-3">
              <p class="group-title">
                {{ "parlamentary_groups.group.text_2" |translate }}
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                1
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Martha Marina González Dávila
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Presidenta
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Nicaragua
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                2
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Rina Floryselva García
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Vice-presidenta
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Honduras
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                3
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Jafeth Ernesto Cabrera Franco
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Secretario
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Guatemala
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                4
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Héctor Eduardo Aparicio Díaz
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Integrante
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Panamá
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                5
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Namibia Angola Didiez Ogando
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Integrante
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                República Dominicana
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                6
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                José Nelson Guardado Menjivar
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Integrante
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                El Salvador
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                7
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Mario Alfredo Moya Lovo
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Integrante
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Honduras
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                8
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Engels Martin Pineda García
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Integrante
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Honduras
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                9
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Rosa Enilda Solis Paulino
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Integrante
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                República Dominicana
              </p>
            </div>
          </div>
        </div>
      </div> -->

      <div class="section-body-container">
        <div class="light-container p-5">
          <div class="row py-4 d-responsive">
            <div class="col-lg-2">
              <p class="group-title">
                No.
              </p>
            </div>
            <div class="col-lg-4">
              <p class="group-title">
                {{ "parlamentary_groups.group.text" |translate }}
              </p>
            </div>
            <div class="col-lg-3">
              <p class="group-title">
                {{ "parlamentary_groups.group.text_1" |translate }}
              </p>
            </div>
            <div class="col-lg-3">
              <p class="group-title">
                {{ "parlamentary_groups.group.text_2" |translate }}
              </p>
            </div>
          </div>

          <div *ngFor="let sl of deputiesCSSS; let i = index;">
            <div class="row pb-4">
              <div class="col-lg-2">
                <p class="info-text">
                  {{i + 1}}
                </p>
              </div>
              <div class="col-lg-4">
                <p class="info-text">
                  {{sl.name}} {{sl.lastname}}
                </p>
              </div>
              <div class="col-lg-3">
                <p class="info-text">
                  {{sl.position}}
                </p>
              </div>
              <div class="col-lg-3">
                <p class="info-text">
                  {{sl.country}}
                </p>
              </div>
            </div>
          </div>



        </div>
      </div>
      <div class="section-title-container pt-5 pb-1">
        <h3 class="section-title">
          {{ "Comisión de Derechos Humanos" | uppercase }}
        </h3>
      </div>
      <!-- <div class="section-body-container">
        <div class="light-container p-5">
          <div class="row py-4 d-responsive">
            <div class="col-lg-2">
              <p class="group-title">
                No.
              </p>
            </div>
            <div class="col-lg-4">
              <p class="group-title">
                {{ "parlamentary_groups.group.text" |translate }}
              </p>
            </div>
            <div class="col-lg-3">
              <p class="group-title">
                {{ "parlamentary_groups.group.text_1" |translate }}
              </p>
            </div>
            <div class="col-lg-3">
              <p class="group-title">
                {{ "parlamentary_groups.group.text_2" |translate }}
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                1
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Alcibíadez Vásquez Velásquez
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Presidente
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Panamá
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                2
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Aura Lily Escobar Anleu
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Vice-Presidenta
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Guatemala
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                3
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Gracia María De Fatima Larrave Rivas
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Secretaia
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                El Salvador
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                4
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Omar Mario Carrizo
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Integrante
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Pananá
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                5
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Larissa Regina Colindres Maldonado
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Integrante
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Nicaragua
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                6
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                William Alfredo Hernández
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Integrante
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                El Salvador
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                7
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                María Alma Judith Flores Porras
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Integrante
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Guatemala
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                8
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Oscar Alberto Martínez
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Integrante
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Honduras
              </p>
            </div>
          </div>

          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                9
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Jóse Francisco Peña Guaba
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Integrante
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                República Dominicana
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                10
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Margarita Cedeño Lizardo
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Integrante
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                República Dominicana
              </p>
            </div>
          </div>
        </div>
      </div> -->

      <div class="section-body-container">
        <div class="light-container p-5">
          <div class="row py-4 d-responsive">
            <div class="col-lg-2">
              <p class="group-title">
                No.
              </p>
            </div>
            <div class="col-lg-4">
              <p class="group-title">
                {{ "parlamentary_groups.group.text" |translate }}
              </p>
            </div>
            <div class="col-lg-3">
              <p class="group-title">
                {{ "parlamentary_groups.group.text_1" |translate }}
              </p>
            </div>
            <div class="col-lg-3">
              <p class="group-title">
                {{ "parlamentary_groups.group.text_2" |translate }}
              </p>
            </div>
          </div>

          <div *ngFor="let sl of deputiesCSCP; let i = index;">
            <div class="row pb-4">
              <div class="col-lg-2">
                <p class="info-text">
                  {{i + 1}}
                </p>
              </div>
              <div class="col-lg-4">
                <p class="info-text">
                  {{sl.name}} {{sl.lastname}}
                </p>
              </div>
              <div class="col-lg-3">
                <p class="info-text">
                  {{sl.position}}
                </p>
              </div>
              <div class="col-lg-3">
                <p class="info-text">
                  {{sl.country}}
                </p>
              </div>
            </div>
          </div>



        </div>
      </div>
      <div class="section-title-container pt-5 pb-1">
        <h3 class="section-title">
          {{ "Comisión De Turismo" | uppercase }}
        </h3>
      </div>
      <!-- <div class="section-body-container">
        <div class="light-container p-5">
          <div class="row py-4 d-responsive">
            <div class="col-lg-2">
              <p class="group-title">
                No.
              </p>
            </div>
            <div class="col-lg-4">
              <p class="group-title">
                {{ "parlamentary_groups.group.text" |translate }}
              </p>
            </div>
            <div class="col-lg-3">
              <p class="group-title">
                {{ "parlamentary_groups.group.text_1" |translate }}
              </p>
            </div>
            <div class="col-lg-3">
              <p class="group-title">
                {{ "parlamentary_groups.group.text_2" |translate }}
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                1
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Juan Manuel Ventura Ureña
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Presidente
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                República Dominicana
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                2
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Uarren Oscar Beita Peña
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                VicePresidente
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Panamá
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                3
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Iliana Guadalupe Calles Domínguez
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Secretaria
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Guatemala
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                4
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Claudia Lorena Alas De Ávila
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Integrante
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                El Salvador
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                5
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                José Antonio Alvarado Correa
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Integrante
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Nicaragua
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                6
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                José Wilfredo Salgado Gómez
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Integrante
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                El Salvador
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                7
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Hiliana Sarmiento Torres
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Integrante
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Honduras
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                8
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Gilberto Manuel Succari
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Integrante
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Panamá
              </p>
            </div>
          </div>
        </div>
      </div> -->

      <div class="section-body-container">
        <div class="light-container p-5">
          <div class="row py-4 d-responsive">
            <div class="col-lg-2">
              <p class="group-title">
                No.
              </p>
            </div>
            <div class="col-lg-4">
              <p class="group-title">
                {{ "parlamentary_groups.group.text" |translate }}
              </p>
            </div>
            <div class="col-lg-3">
              <p class="group-title">
                {{ "parlamentary_groups.group.text_1" |translate }}
              </p>
            </div>
            <div class="col-lg-3">
              <p class="group-title">
                {{ "parlamentary_groups.group.text_2" |translate }}
              </p>
            </div>
          </div>

          <div *ngFor="let sl of deputiesCT; let i = index;">
            <div class="row pb-4">
              <div class="col-lg-2">
                <p class="info-text">
                  {{i + 1}}
                </p>
              </div>
              <div class="col-lg-4">
                <p class="info-text">
                  {{sl.name}} {{sl.lastname}}
                </p>
              </div>
              <div class="col-lg-3">
                <p class="info-text">
                  {{sl.position}}
                </p>
              </div>
              <div class="col-lg-3">
                <p class="info-text">
                  {{sl.country}}
                </p>
              </div>
            </div>
          </div>



        </div>
      </div>
      <div class="section-title-container pt-5 pb-1">
        <h3 class="section-title">
          {{ "Comisión de Asuntos Políticos" | uppercase }}
        </h3>
      </div>
      <!-- <div class="section-body-container">
        <div class="light-container p-5">
          <div class="row py-4 d-responsive">
            <div class="col-lg-2">
              <p class="group-title">
                No.
              </p>
            </div>
            <div class="col-lg-4">
              <p class="group-title">
                {{ "parlamentary_groups.group.text" |translate }}
              </p>
            </div>
            <div class="col-lg-3">
              <p class="group-title">
                {{ "parlamentary_groups.group.text_1" |translate }}
              </p>
            </div>
            <div class="col-lg-3">
              <p class="group-title">
                {{ "parlamentary_groups.group.text_2" |translate }}
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                1
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Altagracia Martha Pérez Campusano
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Presidenta
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                República Dominicana
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                2
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Gerardo Suvillaga
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Vice-Presidente
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                El Salvador
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                3
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Socorro Jacinta Reyes Lara
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Sercretaria
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Nicaragua
              </p>
            </div>
          </div> -->
      <!--div class="row pb-4">
                        <div class="col-lg-2">
                            <p class="info-text">
                                4
                            </p>
                        </div>
                        <div class="col-lg-4">
                            <p class="info-text">
                                Neftalí Alarcón Sandoval
                            </p>
                        </div>
                        <div class="col-lg-3">
                            <p class="info-text">
                                Integrante
                            </p>
                        </div>
                        <div class="col-lg-3">
                            <p class="info-text">
                                Guatemala
                            </p>
                        </div>
                    </div -->
      <!-- <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                6
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Nery Bonilla Amaya
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Integrante
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                El Salvador
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                7
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Walter Reiniery Castellanos Sauceda
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Integrante
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Honduras
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                8
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Omar Antonio Gonzales Gallardo
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Integrante
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Honduras
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                9
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                José Erasmo Portillo Fernández
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Integrante
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Honduras
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                10
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Cirilo Salas Lemos
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Integrante
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Panamá
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                11
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Orlando José Tardencilla
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Integrante
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Nicaragua
              </p>
            </div>
          </div>
        </div>
      </div> -->


      <div class="section-body-container">
        <div class="light-container p-5">
          <div class="row py-4 d-responsive">
            <div class="col-lg-2">
              <p class="group-title">
                No.
              </p>
            </div>
            <div class="col-lg-4">
              <p class="group-title">
                {{ "parlamentary_groups.group.text" |translate }}
              </p>
            </div>
            <div class="col-lg-3">
              <p class="group-title">
                {{ "parlamentary_groups.group.text_1" |translate }}
              </p>
            </div>
            <div class="col-lg-3">
              <p class="group-title">
                {{ "parlamentary_groups.group.text_2" |translate }}
              </p>
            </div>
          </div>

          <div *ngFor="let sl of deputiesCPAP; let i = index;">
            <div class="row pb-4">
              <div class="col-lg-2">
                <p class="info-text">
                  {{i + 1}}
                </p>
              </div>
              <div class="col-lg-4">
                <p class="info-text">
                  {{sl.name}} {{sl.lastname}}
                </p>
              </div>
              <div class="col-lg-3">
                <p class="info-text">
                  {{sl.position}}
                </p>
              </div>
              <div class="col-lg-3">
                <p class="info-text">
                  {{sl.country}}
                </p>
              </div>
            </div>
          </div>



        </div>
      </div>
      <!--
        <div class="section-title-container pt-5 pb-1">
        <h3 class="section-title">
          {{ "COMISIÓN DE GESTIÓN INSTITUCIONAL" | uppercase }}
        </h3>
      </div>
      <!--  <div class="section-body-container">
        <div class="light-container p-5">
          <div class="row py-4 d-responsive">
            <div class="col-lg-2">
              <p class="group-title">
                No.
              </p>
            </div>
            <div class="col-lg-4">
              <p class="group-title">
                {{ "parlamentary_groups.group.text" |translate }}
              </p>
            </div>
            <div class="col-lg-3">
              <p class="group-title">
                {{ "parlamentary_groups.group.text_1" |translate }}
              </p>
            </div>
            <div class="col-lg-3">
              <p class="group-title">
                {{ "parlamentary_groups.group.text_2" |translate }}
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                1
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Jaime René Morales Carazo
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Presidente
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Nicaragua
              </p>
            </div>
          </div>
        </div>
      </div> 

      <div class="section-body-container">
        <div class="light-container p-5">
          <div class="row py-4 d-responsive">
            <div class="col-lg-2">
              <p class="group-title">
                No.
              </p>
            </div>
            <div class="col-lg-4">
              <p class="group-title">
                {{ "parlamentary_groups.group.text" |translate }}
              </p>
            </div>
            <div class="col-lg-3">
              <p class="group-title">
                {{ "parlamentary_groups.group.text_1" |translate }}
              </p>
            </div>
            <div class="col-lg-3">
              <p class="group-title">
                {{ "parlamentary_groups.group.text_2" |translate }}
              </p>
            </div>
          </div>

          <div *ngFor="let sl of deputiesCGI  ; let i = index;">
            <div class="row pb-4">
              <div class="col-lg-2">
                <p class="info-text">
                  {{i + 1}}
                </p>
              </div>
              <div class="col-lg-4">
                <p class="info-text">
                  {{sl.name}} {{sl.lastname}}
                </p>
              </div>
              <div class="col-lg-3">
                <p class="info-text">
                  {{sl.position}}
                </p>
              </div>
              <div class="col-lg-3">
                <p class="info-text">
                  {{sl.country}}
                </p>
              </div>
            </div>
          </div>



        </div>
      </div>
      -->

      
      <div class="section-title-container pt-5 pb-1">
        <h3 class="section-title">
          {{ "DELEGACIÓN DE EUROLAT" | uppercase }}
        </h3>
      </div>
      <div class="section-body-container">
        <div class="light-container p-5">
          <div class="row py-4 d-responsive">
            <div class="col-lg-2">
              <p class="group-title">
                No.
              </p>
            </div>
            <div class="col-lg-4">
              <p class="group-title">
                {{ "parlamentary_groups.group.text" |translate }}
              </p>
            </div>
            <div class="col-lg-3">
              <p class="group-title">
                <!--  {{ "parlamentary_groups.group.text_1" |translate }} -->
              </p>
            </div>
            <div class="col-lg-3">
              <p class="group-title">
                {{ "parlamentary_groups.group.text_2" |translate }}
              </p>
            </div>
          </div>
          <div *ngFor="let sl of deputiesEuro  ; let i = index;">
            <div class="row pb-4">
              <div class="col-lg-2">
                <p class="info-text">
                  {{i + 1}}
                </p>
              </div>
              <div class="col-lg-4">
                <p class="info-text">
                  {{sl.name}} {{sl.lastname}}
                </p>
              </div>
              <div class="col-lg-3">
                <p class="info-text">
                  <!-- Presidente -->
                </p>
              </div>
              <div class="col-lg-3">
                <p class="info-text">
                  {{sl.country}}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>



      <div class="section-body-container d-none">
        <div class="light-container p-5">
          <div class="row py-4 d-responsive">
            <div class="col-lg-2">
              <p class="group-title">
                No.
              </p>
            </div>
            <div class="col-lg-4">
              <p class="group-title">
                {{ "parlamentary_groups.group.text" |translate }}
              </p>
            </div>
            <div class="col-lg-3">
              <p class="group-title">
                <!--  {{ "parlamentary_groups.group.text_1" |translate }} -->
              </p>
            </div>
            <div class="col-lg-3">
              <p class="group-title">
                {{ "parlamentary_groups.group.text_2" |translate }}
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                1
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Fanny Salinas
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                <!-- Presidente -->
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Honduras
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                2
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Daniel Ortega Reyes
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                <!-- Presidente -->
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Nicaragua
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                3
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Carlos Rafael Fion Morales
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                <!-- Presidente -->
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Guatemala
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                4
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Gerardo Suvillaga
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                <!-- Presidente -->
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                El Salvador
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                5
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                William Alfredo Hernández
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                <!-- Presidente -->
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                El Salvador
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                6
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Mónica Broncy
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                <!-- Presidente -->
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Guatemala
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                7
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Juan Pablo Riley
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                <!-- Presidente -->
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Guatemala
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                8
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Gloria Guadalupe Oquelí
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                <!-- Presidente -->
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Honduras
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                9
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Oscar Martínez
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                <!-- Presidente -->
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Honduras
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                10
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Francisco Ramos
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                <!-- Presidente -->
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Nicaragua
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                11
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Rubén de León
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                <!-- Presidente -->
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Panamá
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                12
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Sandra Noriega Sieiro
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                <!-- Presidente -->
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Panamá
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                13
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Silvia García Polanco
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                <!-- Presidente -->
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                República Dominicana
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                14
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Elsido Antonio Díaz Bueno
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                <!-- Presidente -->
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                República Dominicana
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-2">
              <p class="info-text">
                15
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Dr. Alejandro Bravo
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                <!-- Presidente -->
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                Nicaragua
              </p>
            </div>
          </div>
        </div>
      </div>


      
      <div class="section-title-container">
        <h3 class="section-title">{{ "Novedades de la Plenaria" | uppercase }}</h3>
      </div>
      <div class="section-body-container">
        <div class="trabajo-parlacen">
          <h2 class="info-title">Infórmese sobre el trabajo hecho por el PARLACEN</h2>
          <div class="row text-center">
            <div class="col-lg-4">
              <div class="card hecho">
                <div class="card-body hecho-b">
                  <img src="assets/svg/Resoluciones y  declaracionesAzul.svg" class="hecho-img align-self-center"
                    alt="Ícono de Historia" width="34" height="65" loading="lazy">
                  <h2 class="group-title">Resoluciones y declaraciones</h2>
                </div>
                <div class="card-footer">
                  <p class="hecho-f">
                    <button type="button" class="btn btn-outline-dark outline-ver-aqui">
                      <a class="nav-link" href="/resoluciones">{{ "Ver Aquí" | uppercase
                        }}</a>
                    </button>
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="card hecho">
                <div class="card-body nobedad-b">
                  <img src="assets/svg/PronunciamientosAzul.svg" class="hecho-img align-self-center"
                    alt="Ícono de Marco Jurídico y Político" width="28" height="65" loading="lazy">
                  <h2 class="group-title">Actas Parlamento</h2>
                </div>
                <div class="card-footer">
                  <p class="hecho-f">
                    <button type="button" class="btn btn-outline-dark outline-ver-aqui">
                      <a class="nav-link" href="/informes-plenaria">{{ "Ver Aquí" | uppercase
                        }}</a>
                    </button>
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="card hecho">
                <div class="card-body">
                  <img src="assets/svg/Actas e informesAzul.svg" class="hecho-img align-self-center"
                    alt="Ícono de Documentos Parlamentaria" width="28" height="65" loading="lazy">
                  <h2 class="group-title">Informes Parlamento</h2>
                </div>
                <div class="card-footer">
                  <p class="hecho-c">
                    <button type="button" class="btn btn-outline-dark outline-ver-aqui">
                      <a class="nav-link" href="/informes-plenaria">{{ "Ver Aquí" | uppercase
                        }}</a>
                    </button>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-5 pt-5">
          <h3 class="section-title">{{ "Conocer más" | uppercase }}</h3>
        </div>
        <div class="row conocer-mas text-white">
          <div class="col-lg-6 text-center align-self-center ti-text">
            <p class="ti-t">¿Deseas conocer nuestro reglamento interno?</p>
            <button type="button" class="btn btn-primary ti-btn" href="assets/pdf/Reglamento_Inteno_2022_PARLACEN.pdf"> {{
              "Ver Aquí" | uppercase }}</button>
          </div>
          <div class="col-lg-6 p-0 ti-image">
            <img src="assets/img/Organizacion--Informes-plenaria.png" width="100%" class="ti-img"
              alt="Diputados del Parlamento Centroamericano" loading="lazy"
              srcset="assets/img/Organizacion--Informes-plenaria.png 1x, assets/img/Organizacion--Informes-plenaria.png 2x">
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="light-container">
    <div class="container-lg">
      <div class="my-container">
        <div class="section-title-container cuadro-admin-pasadas">
          <h3 class="section-title">{{ "Cuadro histórico de administraciones pasadas" | uppercase }}</h3>
        </div>
        <div class="section-body-container">
          <h2 class="info-title">Histórico Administración Comisiones PARLACEN</h2>
        </div>
        <div class="cuadro-admin">
          <div class="row text-center pt-3">
            <div class="col-lg-3">
              <div class="card hecho">
                <div class="card-body hecho-b">
                  <h2 class="group-title">2019-2020</h2>
                </div>
                <div class="card-footer">
                  <p class="hecho-f">
                    <button type="button" class="btn btn-outline-dark outline-ver-aqui">
                      <a class="nav-link" href="assets/pdf/COMISIONES 2017-2020.xlsx - 2019-2020.pdf">{{
                        "Consultar" | uppercase }}</a>
                    </button>
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-3">
              <div class="card hecho">
                <div class="card-body nobedad-b">
                  <h2 class="group-title">2018-2019</h2>
                </div>
                <div class="card-footer">
                  <p class="hecho-f">
                    <button type="button" class="btn btn-outline-dark outline-ver-aqui">
                      <a class="nav-link" href="assets/pdf/COMISIONES 2017-2020.xlsx - 2018-2019.pdf">{{
                        "Consultar" | uppercase }}</a>
                    </button>
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-3">
              <div class="card hecho">
                <div class="card-body">
                  <h2 class="group-title">2017-2018</h2>
                </div>
                <div class="card-footer">
                  <p class="hecho-c">
                    <button type="button" class="btn btn-outline-dark outline-ver-aqui">
                      <a class="nav-link" href="assets/pdf/COMISIONES 2017-2020.xlsx - 2017-2018.pdf">{{
                        "Consultar" | uppercase }}</a>
                    </button>
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-3">
              <div class="card hecho">
                <div class="card-body">
                  <h2 class="group-title">2016-2017</h2>
                </div>
                <div class="card-footer">
                  <p class="hecho-c">
                    <button type="button" class="btn btn-outline-dark outline-ver-aqui">
                      <a class="nav-link" href="assets/pdf/COMISIONES 2016-2017.pdf">{{
                        "Consultar" | uppercase }}</a>
                    </button>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
